body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sidebar-table-of-contents {
  width: 300px;
  height: 100vh;
}

.sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-item-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-item-icon {
  margin-right: 6px;
}

.sidebar-item-text {
  width: 100%;
}

.sidebar-item-expand-arrow {
  font-size: 1.2rem !important;
}

.sidebar-item-expand-arrow-expanded {
  color: #09bb12;
  font-weight: bold;
}

.sidebarIconsNav {
  /* max-width: 60px; // width if it's icon only */
  max-width: 300px;
  height: 100vh;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebarDashboard {
  max-width: 300px;
  height: 250vh;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Settings Sidebar */
.setting-sidebar {
  width: 340px;
  height: 100vh;
  border: 1px solid #e0e0e0;
}
.setting-sidebar h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding: 24px 20px 0px 20px;
}
.setting-sidebar h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-align: left;
  border-bottom: none;
  padding: 10px 20px 0px 20px;
}
.labels-wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.labels-wrap label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.labels-wrap p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #bdbdbd;
}

.text-fields {
  margin-bottom: 25px;
}
.text-fields input {
  padding: 10px;
}
.text-fields textarea {
  padding: 10px;
}
.chip-wrap {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e0e0e0;
  max-height: 150px;
  overflow-y: scroll;
  padding: 10px;
}
.chip {
  display: flex;
  background-color: #000000;
  color: #fff;
  padding: 5px 12px;
  margin: 5px 0px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
.chipIcon {
  display: flex !important;
  align-self: center !important;
  color: #fff;
  font-size: 18px !important;
  margin-left: 10px;
}
.auto-complete {
  padding: 20px 20px;
}
.permission-modal {
  padding: 20px;
}
.select-wrap {
  position: relative;
}
.permission-modal p {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  padding-bottom: 10px;
}
.permission-modal h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #2f80ed;
  padding-bottom: 14px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.arrowImage {
  position: absolute;
  right: 23px;
  top: 2px;
  background-color: #f0f0f0;
  padding: 8px;
}
