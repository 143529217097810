.saveIcon {
  width: 19px;
  height: 19.29px;
  margin-right: 10px;
}
.title-wrapper {
  display: flex;
  margin-bottom: 37px;
}
#customized-dialog-title {
  padding: 16px 8px !important;
}
#customized-dialog-title h6 {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.card {
  height: 100px;
  margin-bottom: 20px;
}
.modal-contentText {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding-bottom: 15px;
}
.content-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #828282;
  margin-bottom: 10px;
}
.details {
  display: flex;
  flex-direction: column;
  width: 450px;
}
.diction {
  display: flex;
}
.coverCard {
  padding: 10px 20px !important;
}
.makeStyles-coverContainer-32 {
  width: 160px !important;
  height: 80px !important;
}
.card-content {
  display: flex;
  flex-direction: column;
}
.new-collection-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.cardActionArea {
  max-width: 500px;
}
.cancelButton {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #2f80ed !important;
}

.modal-new-collect {
  margin-left: 25px !important;
  margin-bottom: 30px !important;
  margin-top: 30px !important;
  width: 180px !important;
  height: 36px !important;
  font-family: Inter !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  border: 1px solid #2f80ed !important;
  color: #2f80ed !important;
}
.action-Wrapper {
  padding: 40px !important;
}
.form {
  margin-bottom: 30px;
}
.form-wrap {
  display: flex;
  justify-content: space-around;
}
.form-new-collection-input {
  display: flex;
  padding-top: 30px !important;
  padding-left: 30px !important;
}
.input-field {
  width: 300px;
}
.btn-wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  padding-left: 30px;
}
.btn-wrap .btn {
  margin: 0px 5px;
}
.public-privte-wrap {
  display: flex;
}
.public-privte-wrap .lock {
  width: 18px;
  margin-left: 5px;
}
.public-privte-wrap .public {
  width: 18px;
  margin-left: 7px;
}
